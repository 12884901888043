<template>
  <div v-if="entry">
    <router-link
      :to="entry.heroCtaUrl ? localizedRoute(entry.heroCtaUrl) : '/'"
      class="relative z-10 hover:text-white transition-all block h-[655px] lg:h-[655px] 2xl:h-[1200px]"
    >
      <div class="rc-overlay">
        <h2
          v-if="entry.heroHeadingOne"
          :class="headingOneStyles"
          class="rc-heading-one uppercase font-featured text-white leading-none m-0"
        >
          {{ entry.heroHeadingOne }}
        </h2>

        <h3
          v-if="entry.heroHeadingTwo"
          :class="headingTwoStyles"
          class="rc-heading-two uppercase font-featured text-white mb-2 leading-none md:mb-5"
        >
          {{ entry.heroHeadingTwo }}
        </h3>

        <div v-if="entry.heroCtaText" class="inline-block">
          <div
            class="flex items-center gap-2 bg-black text-white px-2 py-3 md:px-3 md:py-4"
          >
            <span class="uppercase font-bold md:text-xl">
              {{ entry.heroCtaText }}
            </span>
            <ArrowRight class="h-4" />
          </div>
        </div>
      </div>

      <video
        v-if="hasVideo"
        :poster="image"
        class="bg-blend-multiply w-full h-[655px] lg:h-[655px] 2xl:h-[1200px]"
        preload="auto"
        playsinline
        autoplay
        loop
        muted
      >
        <source
          v-if="mobileVideo"
          :src="mobileVideo"
          type="video/mp4"
          media="(max-width: 640px)"
          width="640"
          height="640"
        />
        <source
          v-if="video"
          :src="video"
          type="video/mp4"
          media="(min-width: 641px)"
          width="1024"
          height="655"
        />
        <source
          v-if="video"
          :srcset="video"
          media="(min-width: 1281px)"
          width="1200"
          height="1200"
        />
      </video>

      <picture v-if="hasImage && !hasVideo"
        class="bg-blend-multiply w-full h-[655px] lg:h-[655px] 2xl:h-[1200px]"
      >
        <source
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(max-width: 640px)"
          width="640"
          height="640"
        />
        <source
          v-if="image"
          :srcset="image"
          media="(min-width: 641px)"
          width="1920"
          height="1080"
        />
        <source
          v-if="image"
          :srcset="image"
          media="(min-width: 1281px)"
          width="1200"
          height="1200"
        />
        <img
          :src="image"
          alt=""
          class="w-full h-[655px] lg:h-[655px] 2xl:h-[1200px]"
        />
      </picture>
    </router-link>
  </div>
</template>

<script>
import config from 'config';
import { mapGetters } from 'vuex';
import ArrowRight from '../../../theme/bikebuilder/svg/ui/ArrowRight.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  components: {
    ArrowRight
  },
  computed: {
    ...mapGetters('craftCms', ['homeHero']),
    entry() {
      if (!this.homeHero) return null;
      return this.homeHero.entries.find((entry) => entry.status === 'live');
    },
    image() {
      if (
        !this.entry ||
        !this.entry.heroImage ||
        !this.entry.heroImage.length
      ) {
        return '';
      }
      const image = this.entry.heroImage[0].url;
      return `${config.speedSizeUrl}/${image}`;
    },
    video() {
      const entry = this.entry;
      if (!entry || !entry.heroVideo || !entry.heroVideo.length) {
        return '';
      }
      const video = entry.heroVideo[0].url;
      return `${config.speedSizeUrl}/${video}`;
    },
    mobileImage() {
      if (!this.entry) return '';
      const image =
        this.entry.mobileImage && this.entry.mobileImage.length
          ? this.entry.mobileImage[0].url
          : this.image;
      return image ? `${config.speedSizeUrl}/${image}` : '';
    },
    mobileVideo() {
      if (!this.entry) return '';
      const video =
        this.entry.mobileVideo && this.entry.mobileVideo.length
          ? this.entry.mobileVideo[0].url
          : this.video;
      return video ? `${config.speedSizeUrl}/${video}` : '';
    },
    headingOneStyles() {
      const hasStyle = this.entry && this.entry.headingStyles.length;
      const isBold = this.entry.headingStyles.find(
        (style) => style === 'headingOneBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    headingTwoStyles() {
      const hasStyle = this.entry && this.entry.headingStyles.length;
      const isBold = this.entry.headingStyles.find(
        (style) => style === 'headingTwoBold'
      );
      return hasStyle && isBold ? 'font-bold' : 'font-thin';
    },
    hasVideo() {
      return !!(this.video || this.mobileVideo);
    },
    hasImage() {
      return !!(this.image || this.mobileImage);
    }
  }
};
</script>

<style scoped>
/* Override the ayko styles */
#app a:not(.menu-link):not(.action):not(.link):hover span {
  color: #fff;
}
.rc-overlay {
  top: 60%;
  left: 50%;
  width: 90vw;
  text-align: left;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.rc-heading-one {
  font-size: 28px;
}
.rc-heading-two {
  font-size: 28px;
}
@media (min-width: 768px) {
  .rc-heading-one {
    font-size: 50px;
  }
  .rc-heading-two {
    font-size: 50px;
  }
}
@media (min-width: 1280px) {
  .rc-heading-one {
    font-size: 70px;
  }
  .rc-heading-two {
    font-size: 70px;
  }
}
</style>
